import React, { useState, useEffect} from 'react'
import { Link, useNavigate } from "react-router-dom"
import { addAirtimePhoto } from '../../redux/slice/airtimeSlice/airtimePhotoSlice'
import { addAirtimeOrder } from '../../redux/slice/airtimeSlice/airtimeSlice'
import {  postAirtimeTransaction,  } from '../../utils/processTransactions/processAirtime/airtimeTransactions'
import { useAppSelector, useAppDispatch } from "../../utils/TypeScript"
import Spinner from '../Alert/Spinner/Spinner'
import { toast } from 'react-toastify'


const RechargeDoneComp = () => {

    const [loading, setLoading] =useState(false)
    const [success, setSuccess] =useState(false)

    const dispatch = useAppDispatch()
    const navigate = useNavigate()


    const { currentAirtimePhoto} = useAppSelector(state => state.airtimePhoto)
    const { currentAirtimeOrder} = useAppSelector(state => state.airtime)
    

    const redirectToHome = () =>{
        setTimeout(()=>{
            navigate("/")
        }, 50000)
    }

    const postTransaction = async () => {
        if (!currentAirtimeOrder) return; 
        
        console.log({currentAirtimeOrder})
        try {
            setLoading(true);
            const transactionResponse = await postAirtimeTransaction(currentAirtimeOrder);
            console.log({transactionResponse});
    
            if (transactionResponse?.responseMessage?.toLowerCase() === "successful") {
                setSuccess(true) 
                toast.success("Transaction completed successfully!");
                localStorage.clear();
                // redirectToHome();hhh
            } else {
                throw new Error("Transaction failed. Please try again.");
            }
        } catch (error: any) {
            toast.error(error.message || "An unexpected error occurred.");
        } finally {
            setLoading(false);
        }
    };
    
  
   

    // const getFromLocalStorage = async ()=>{
    //     setLoading(true)

    //     const airtimePhoto =  JSON.parse(localStorage.getItem('airtimePhoto') || "{}");
    //     const airtimeOrder =  JSON.parse(localStorage.getItem('airtimeOrder') || "{}");
       

    //     dispatch(addAirtimePhoto(airtimePhoto))
    //     dispatch(addAirtimeOrder(airtimeOrder))
       

    // }


    // useEffect(()=>{
    //     if(localStorage.getItem('airtimePhoto') === null ) return navigate("/")

    //     getFromLocalStorage()
    // }, [ ])
    
    useEffect(()=>{
        if(localStorage.getItem('payment') === null ) return navigate("/")

        const payment =  localStorage.getItem('payment') || " ";
        if(payment !== "i") return setLoading(false)
           
            if (currentAirtimeOrder && Object.keys(currentAirtimeOrder).length > 0) {
                postTransaction();
            }
    }, [currentAirtimeOrder])




  return (
    <div className="col-lg-9">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="bg-light shadow-sm text-center mb-3">
                                <div className="d-flex admin-heading pr-3">
                                    <div className="mr-auto">
                                    <h4 className="text-9 font-weight-400" style={{color: "#3f0d5e"}}><i className="fas fa-wallet mr-2"></i>
                                    {
                                        currentAirtimePhoto?.billerCode === '9MOBILE_69358' ? "9Mobile Airtime"
                                        : 
                                        currentAirtimePhoto?.billerCode === 'AIRTEL_22689' ? "Airtel Airtime"
                                        : 
                                        currentAirtimePhoto?.billerCode === 'GLO_30387' ? "Glo Airtime"
                                        : 
                                        currentAirtimePhoto?.billerCode === 'MTN_24207' ? 'MTN Airtime'
                                        :
                                        currentAirtimePhoto?.billerCode === 'NTEL' ? "Ntel Airtime"
                                        :
                                        ""
                                    }
                                
                                    </h4>
                                    </div>
                                    <div className="ml-auto">
                                    <h4 className="text-9 font-weight-400" style={{color: "#3f0d5e"}}>₦
                                    {
                                        currentAirtimeOrder?.details?.amount
                                    }
                                    </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-md-12 profile-content ">
                        <ul className="nav nav-pills">
                
                            <li className="nav-item">
                                <Link className="nav-link" to="">Order</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="">Summary</Link>
                            </li>
                            {/* <li className="nav-item">
                                <Link className="nav-link" to="/recharge-payment">Payment</Link>
                            </li> */}
                            <li className="nav-item">
                                <Link className="nav-link active" to="" >Done</Link>
                            </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="five" role="tabpanel" aria-labelledby="five-tab">
                                
                                {
                                    loading ? 

                                        ""
                                    :
                                
                                
                                        <div className="done my-5">
                                            <h3 className="title">
                                                {
                                                    success ? 
                                                        "Transaction Successful"
                                                    :
                                                        "Transaction Failed"
                                                }
                                            </h3>

                                            <span className={`text-5 d-block  mt-4 mb-3 ${success? "text-success" : "text-danger"}`}>
                                                {
                                                    success ? 
                                                        <i className="fas fa-check-circle"></i>
                                                    :
                                                        <i className="fas fa-times-circle"></i>
                                                }
                                            </span>

                                            {/* <p>Your payment ₦{currentOrder?.amount} {success ? "    was successfully" : "failed"} <br/>
                                                to receive <b>+1 408 7094360</b> Mobile Operator.
                                            </p> */}

                                            <p> <span>Your </span>
                                            
                                                    {
                                                        currentAirtimePhoto?.billerCode === '9MOBILE_69358' ? "9Mobile"
                                                        : 
                                                        currentAirtimePhoto?.billerCode === 'AIRTEL_22689' ? "Airtel"
                                                        : 
                                                        currentAirtimePhoto?.billerCode === 'GLO_30387' ? "Glo "
                                                        : 
                                                        currentAirtimePhoto?.billerCode === 'MTN_24207' ? 'MTN'
                                                        :
                                                        currentAirtimePhoto?.billerCode === 'NTEL' ? "Ntel "
                                                        :
                                                        ""
                                                    }

                                                <span> recharge of ₦</span>
                                                    {
                                                        currentAirtimeOrder?.details?.amount
                                                    } 
                                                <br/>
                                                to <b>
                                                    {
                                                        currentAirtimeOrder?.details?.phoneNumber
                                                    } 
                                                </b>
                                                <span> {success ? "was successful" : "failed"}.</span>
                                                
                                                {success ? " Thank you for using PreponeBills" : " Please try again."}
                                            </p>

                                            {/* <div className="invoice-option text-center">
                                                <Link to="#" className="invoice-btn"><i className="far fa-file-pdf"></i>Save as PDF</Link>
                                                <Link to="#" className="invoice-btn"><i className="fas fa-print"></i>Print Receipt</Link>
                                                <Link to="#" className="invoice-btn"><i className="far fa-envelope"></i>Email Receipt</Link>
                                                <p className="mt-4 mb-0"><Link to="/recharge-order" className="btn btn-default">Make another Recharge</Link></p>
                                            </div> */}
                                        </div>

                                }


                            </div>
                        </div>
                    </div>
                    { loading && <Spinner /> }
                </div>
  )
}

export default RechargeDoneComp