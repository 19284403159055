import axios from "axios";
import { toast } from "react-toastify";
import { ICurrentAirtelDataOrder, ICurrentGloDataOrder, ICurrentMTNDataOrder, ICurrentNineMobileDataOrder, ICurrentNtelDataOrder, ICurrentSmileDataOrder, ICurrentSpectranetDataOrder } from "../../AirtimeDateTypeScript/airtimeDataTypes";


export const postDataTransaction = async ( payload: Object  ) => {
    try {
        
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/process-transactions`, 
        {
          'url': `${process.env.REACT_APP_BASE_API_URL_LIVE}`,
          'publicKey': `${process.env.REACT_APP_BILLERS_XPRESSPAY_LIVE_PUBLIC_KEY}`,
          'privateKey': `${process.env.REACT_APP_BILLERS_XPRESSPAY_LIVE_PRIVATE_KEY}`,
          endpoint: "data",
          payload
        },
        {headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )

        return res.data;
     
    } catch (err: any) {
        toast.error(err.response?.data?.msg || err.message);   
        throw new Error(err.response?.data?.msg || "Transaction failed.");  
    }

}


export const postAirtelDataTransaction = async ( payload: ICurrentAirtelDataOrder | undefined  ) => {
    try {
        
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/process-transaction`, 
        {
          'url': `${process.env.REACT_APP_BASE_API_URL}`,
          'authorization': `${process.env.REACT_APP_PREPONE_AUTHORIZATION}`,
          'webkey': `${process.env.REACT_APP_PREPONE_BILLS_WEBKEY}`,
          'accountid': `${process.env.REACT_APP_PREPONE_BILLS_ACCOUNTID}`,
          payload
        },
        {headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )
        
        const { responseMessage } = res.data
        console.log(responseMessage)

        return responseMessage;
     
    } catch (err: any) {
        return toast.error(err.message) 
    }

}


export const postMTNDataTransaction = async ( payload: ICurrentMTNDataOrder | undefined  ) => {
    try {
        
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/process-transaction`, 
        {
          'url': `${process.env.REACT_APP_BASE_API_URL}`,
          'authorization': `${process.env.REACT_APP_PREPONE_AUTHORIZATION}`,
          'webkey': `${process.env.REACT_APP_PREPONE_BILLS_WEBKEY}`,
          'accountid': `${process.env.REACT_APP_PREPONE_BILLS_ACCOUNTID}`,
          payload
        },
        {headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )
        
        const { responseMessage } = res.data
        console.log(responseMessage)

        return responseMessage;
     
    } catch (err: any) {
        return toast.error(err.message) 
    }
}


export const postGloDataTransaction = async ( payload: ICurrentGloDataOrder | undefined  ) => {
    try {
        
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/process-transaction`, 
        {
          'url': `${process.env.REACT_APP_BASE_API_URL}`,
          'authorization': `${process.env.REACT_APP_PREPONE_AUTHORIZATION}`,
          'webkey': `${process.env.REACT_APP_PREPONE_BILLS_WEBKEY}`,
          'accountid': `${process.env.REACT_APP_PREPONE_BILLS_ACCOUNTID}`,
          payload
        },
        {headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )
        
        const { responseMessage } = res.data
        console.log(responseMessage)

        return responseMessage;
     
    } catch (err: any) {
        return toast.error(err.message) 
    }
}


export const postNinemobileDataTransaction = async ( payload: ICurrentNineMobileDataOrder | undefined  ) => {
    try {
        
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/process-transaction`, 
        {
          'url': `${process.env.REACT_APP_BASE_API_URL}`,
          'authorization': `${process.env.REACT_APP_PREPONE_AUTHORIZATION}`,
          'webkey': `${process.env.REACT_APP_PREPONE_BILLS_WEBKEY}`,
          'accountid': `${process.env.REACT_APP_PREPONE_BILLS_ACCOUNTID}`,
          payload
        },
        {headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )
        
        const { responseMessage } = res.data
        console.log(responseMessage)

        return responseMessage;
     
    } catch (err: any) {
        return toast.error(err.message) 
    }
}


export const postNtelDataTransaction = async ( payload: ICurrentNtelDataOrder | undefined  ) => {
    try {
        
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/process-transaction`, 
        {
          'url': `${process.env.REACT_APP_BASE_API_URL}`,
          'authorization': `${process.env.REACT_APP_PREPONE_AUTHORIZATION}`,
          'webkey': `${process.env.REACT_APP_PREPONE_BILLS_WEBKEY}`,
          'accountid': `${process.env.REACT_APP_PREPONE_BILLS_ACCOUNTID}`,
          payload
        },
        {headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )
        
        const { responseMessage } = res.data
        console.log(responseMessage)

        return responseMessage;
     
    } catch (err: any) {
        return toast.error(err.message) 
    }
}


export const postSmileDataTransaction = async ( payload: ICurrentSmileDataOrder | undefined  ) => {
    try {
        
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/process-transaction`, 
        {
          'url': `${process.env.REACT_APP_BASE_API_URL}`,
          'authorization': `${process.env.REACT_APP_PREPONE_AUTHORIZATION}`,
          'webkey': `${process.env.REACT_APP_PREPONE_BILLS_WEBKEY}`,
          'accountid': `${process.env.REACT_APP_PREPONE_BILLS_ACCOUNTID}`,
          payload
        },
        {headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )
        
        const { responseMessage } = res.data
        console.log(responseMessage)

        return responseMessage;
     
    } catch (err: any) {
        return toast.error(err.message) 
    }
}


export const postSpectranetTransaction = async ( payload: ICurrentSpectranetDataOrder | undefined  ) => {
    try {
        
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/process-transaction`, 
        {
          'url': `${process.env.REACT_APP_BASE_API_URL}`,
          'authorization': `${process.env.REACT_APP_PREPONE_AUTHORIZATION}`,
          'webkey': `${process.env.REACT_APP_PREPONE_BILLS_WEBKEY}`,
          'accountid': `${process.env.REACT_APP_PREPONE_BILLS_ACCOUNTID}`,
          payload
        },
        {headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )
        
        const { responseMessage } = res.data
        console.log(responseMessage)

        return responseMessage;
     
    } catch (err: any) {
        return toast.error(err.message) 
    }
}