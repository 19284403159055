import React, { useEffect, useState} from 'react'
import { Link, useNavigate } from "react-router-dom"
import { toast } from 'react-toastify'
import { addElectricityPhoto } from '../../redux/slice/electricitySlice/electricityPhotoSlice'
import { addElectricityOrder } from '../../redux/slice/electricitySlice/electSlice'
import {   postElectricityTransaction, sendElecTransEmail, sendElectricityTransEmail } from '../../utils/processTransactions/processElectricity/processElectricityTransactions'
// import { toast } from 'react-toastify'
import { useAppDispatch, useAppSelector, } from "../../utils/TypeScript"
import Spinner from '../Alert/Spinner/Spinner'


const ElectricityDoneComp = () => {

    const [success, setSuccess] =useState(false)
    const [loading, setLoading] =useState(false)
    const [post, setPost] =useState(false)
    const [token, setToken] =useState("")
    

    const dispatch = useAppDispatch()
    const navigate = useNavigate()


    const { currentElectricityPhoto } = useAppSelector(state => state.electricityPhoto)
    const { currentElectricityOrder } = useAppSelector(state => state.electricity)
    


    const userEmail = currentElectricityOrder? currentElectricityOrder.details.email : ""

    const product =  currentElectricityPhoto?.billerCode === 'ABJ_POSTPAID' ? "Abuja Postpaid"
                        :
                        currentElectricityPhoto?.billerCode === 'ABJ_PREPAID' ? "ABUJA PREPAID"
                        : 
                        currentElectricityPhoto?.billerCode === 'EKEDC_ORDER_PAYMENT' ? 'EKEDC ORDERPAYMENT'
                        :
                        currentElectricityPhoto?.billerCode === 'EKEDC_POSTPAID' ? 'EKEDC Postpaid'
                        :
                        currentElectricityPhoto?.billerCode === 'EKEDC_PREPAID'? 'EKEDC Prepaid'
                        :
                        currentElectricityPhoto?.billerCode === 'EEDC'? 'EEDC'
                        :
                        currentElectricityPhoto?.billerCode === "PHEDDIR2"? "PHED"
                        :
                        currentElectricityPhoto?.billerCode === 'IBEDC_F'? "IBEDC"
                        :
                        currentElectricityPhoto?.billerCode === 'iedc postpaid'? "iedc Postpaid"
                    :
                    currentElectricityPhoto?.billerCode === 'iedc'? "iedc Prepaid"
                    :
                    currentElectricityPhoto?.billerCode === 'JOS_POSTPAID'? "Jos Electric Postpaid"
                    :
                    currentElectricityPhoto?.billerCode === 'JOS_PREPAID'? "Jos Prepaid Electric"
                    :
                    currentElectricityPhoto?.billerCode === 'KADUNA_POSTPAID'? "Kaduna Electric Postpaid"
                    :
                    currentElectricityPhoto?.billerCode === 'KADUNA_PREPAID'? "Kaduna Electric Prepaid"
                    :
                    currentElectricityPhoto?.billerCode === 'KEDCOPostpaid'? "Kano Electric Postpaid"
                    :
                    currentElectricityPhoto?.billerCode === 'KEDCOPrepaid'? "Kano Electric Prepaid"
                    :
                        ""


    // const redirectToHome = () =>{
    //     setTimeout(()=>{
            
    //         localStorage.clear();
    //     }, 50000)
    // }

    

    const postTransaction = async () => {
        if (!currentElectricityOrder) return; 
        
        try {
            setLoading(true);
            const transactionResponse = await postElectricityTransaction(currentElectricityOrder);
    
            if (transactionResponse?.responseMessage?.toLowerCase() === "successful") {
                setToken(transactionResponse?.data?.token);
                // await sendElectricityTransEmail(
                //     userEmail,
                //     product,
                //     transactionResponse
                // );
                toast.success("Transaction completed successfully!");
                setSuccess(true) 
                localStorage.clear();
               
            } else {
                throw new Error("Transaction failed. Please try again.");
            }
        } catch (error: any) {
            toast.error(error.message || "An unexpected error occurred.");
        } finally {
            setLoading(false);
        }
    };


    // const getFromLocalStorage = ()=>{
    //     const electricityPhoto =  JSON.parse(localStorage.getItem('electricityPhoto') || "{}");
    //     const electricityOrder =  JSON.parse(localStorage.getItem('electricityOrder') || "{}");


    //     dispatch(addElectricityPhoto(electricityPhoto))
    //     dispatch(addElectricityOrder(electricityOrder))


    //     setPost(true)

    // }

    // useEffect(()=>{
    //     if(localStorage.getItem('electricityPhoto') === null )  return navigate("/")
    //     getFromLocalStorage() 

    // }, [])  

    useEffect(()=>{
        // if(localStorage.getItem('electricityPhoto') === null ) return navigate("/")
        if(localStorage.getItem('payment') === null ) return navigate("/")

        const payment =  localStorage.getItem('payment') || " ";
        if(payment !== "i") return setLoading(false)
        
            if (currentElectricityOrder && Object.keys(currentElectricityOrder).length > 0) {
                postTransaction();
            }
        
    }, [currentElectricityOrder])

  



  return (
    <div className="col-lg-9">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="bg-light shadow-sm text-center mb-3">
                                <div className="d-flex admin-heading pr-3">
                                    <div className="mr-auto">
                                        
                                        <h4 className="text-9 font-weight-400" style={{color: "#3f0d5e"}}><i className="fas fa-wallet mr-2"></i>
                                            {
                                                 currentElectricityPhoto?.billerCode === 'ABJ_POSTPAID' ? "Abuja Postpaid"
                                                 : 
                                                 currentElectricityPhoto?.billerCode === 'ABJ_PREPAID' ? "Abuja Prepaid"
                                                 : 
                                                 currentElectricityPhoto?.billerCode === 'EKEDC_ORDER_PAYMENT' ? "EKEDC ORDERPAYMENT"
                                                 : 
                                                 currentElectricityPhoto?.billerCode === 'EKEDC_POSTPAID' ? "EKEDC Postpaid"
                                                 : 
                                                 currentElectricityPhoto?.billerCode === 'EKEDC_PREPAID' ? "EKEDC Prepaid"
                                                 : 
                                                 currentElectricityPhoto?.billerCode === 'EEDC' ? "EEDC"
                                                 : 
                                                 currentElectricityPhoto?.billerCode === 'IBEDC_F' ? "IBEDC"
                                                 : 
                                                 currentElectricityPhoto?.billerCode === 'iedc postpaid' ? "iedc Postpaid"
                                                 : 
                                                 currentElectricityPhoto?.billerCode === 'iedc' ? "iedc Prepaid"
                                                 : 
                                                 currentElectricityPhoto?.billerCode === 'JOS_POSTPAID' ? "Jos Electric Postpaid"
                                                 : 
                                                 currentElectricityPhoto?.billerCode === 'JOS_PREPAID' ? "Jos Prepaid Electric"
                                                 : 
                                                 currentElectricityPhoto?.billerCode === 'KADUNA_POSTPAID' ? "Kaduna Electric Postpaid"
                                                 : 
                                                 currentElectricityPhoto?.billerCode === 'KADUNA_PREPAID' ? "Kaduna Electric Prepaid"
                                                 : 
                                                 currentElectricityPhoto?.billerCode === 'KEDCOPostpaid' ? "Kano Electric Postpaid"
                                                 : 
                                                 currentElectricityPhoto?.billerCode === 'KEDCOPrepaid' ? "Kano Electric Prepaid"
                                                 : 
                                                 currentElectricityPhoto?.billerCode === 'PHEDDIR2' ? "PHED"
                                                 : 
                                                 ""
                                            }
                                        </h4>
                                    
                                    </div>
                                    <div className="ml-auto">
                                        <h4 className="text-9 font-weight-400" style={{color: "#3f0d5e"}}>₦
                                            {
                                                currentElectricityOrder?.details.amount
                                            }
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-md-12 profile-content ">
                        <ul className="nav nav-pills">
                
                            <li className="nav-item">
                                <Link className="nav-link" to="">Order</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="">Summary</Link>
                            </li>
                            {/* <li className="nav-item">
                                <Link className="nav-link" to="/recharge-payment">Payment</Link>
                            </li> */}
                            <li className="nav-item">
                                <Link className="nav-link active" to="" >Done</Link>
                            </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="five" role="tabpanel" aria-labelledby="five-tab">
                                

                                {
                                    loading ?

                                        ""

                                    :
                                
                                
                                        <div className="done my-5">
                                            <h3 className="title">
                                                {
                                                    success ? 
                                                        "Transaction Successful"
                                                    :
                                                        "Transaction Failed"
                                                }
                                            </h3>

                                            <span className={`text-5 d-block  mt-4 mb-3 ${success? "text-success" : "text-danger"}`}>
                                                {
                                                    success ? 
                                                        <i className="fas fa-check-circle"></i>
                                                    :
                                                        <i className="fas fa-times-circle"></i>
                                                }
                                            </span>

                                            
                                            


                                            <p> <span>Your </span>
                                                

                                                    { 
                                                        currentElectricityPhoto?.billerCode === 'ABJ_POSTPAID' ? "Abuja Postpaid"
                                                        :
                                                        currentElectricityPhoto?.billerCode === 'ABJ_PREPAID' ? "ABUJA PREPAID"
                                                        : 
                                                        currentElectricityPhoto?.billerCode === 'EKEDC_ORDER_PAYMENT' ? 'EKEDC ORDERPAYMENT'
                                                        :
                                                        currentElectricityPhoto?.billerCode === 'EKEDC_POSTPAID' ? 'EKEDC Postpaid'
                                                        :
                                                        currentElectricityPhoto?.billerCode === 'EKEDC_PREPAID'? 'EKEDC Prepaid'
                                                        :
                                                        currentElectricityPhoto?.billerCode === 'EEDC'? 'EEDC'
                                                        :
                                                        currentElectricityPhoto?.billerCode === "PHEDDIR2"? "PHED"
                                                        :
                                                        currentElectricityPhoto?.billerCode === 'IBEDC_F'? "IBEDC"
                                                        :
                                                        currentElectricityPhoto?.billerCode === 'iedc postpaid'? "iedc Postpaid"
                                                       :
                                                       currentElectricityPhoto?.billerCode === 'iedc'? "iedc Prepaid"
                                                       :
                                                       currentElectricityPhoto?.billerCode === 'JOS_POSTPAID'? "Jos Electric Postpaid"
                                                       :
                                                       currentElectricityPhoto?.billerCode === 'JOS_PREPAID'? "Jos Prepaid Electric"
                                                       :
                                                       currentElectricityPhoto?.billerCode === 'KADUNA_POSTPAID'? "Kaduna Electric Postpaid"
                                                       :
                                                       currentElectricityPhoto?.billerCode === 'KADUNA_PREPAID'? "Kaduna Electric Prepaid"
                                                       :
                                                       currentElectricityPhoto?.billerCode === 'KEDCOPostpaid'? "Kano Electric Postpaid"
                                                       :
                                                       currentElectricityPhoto?.billerCode === 'KEDCOPrepaid'? "Kano Electric Prepaid"
                                                       :
                                                        ""
                                                    } 
                                                    
                                                    <span> subscription of ₦</span>
                                                    
                                                    {
                                                        currentElectricityOrder?.details.amount
                                                    } 
                                                    
                                                <br/>
                                                to <b>
                                                    
                                                    {
                                                        currentElectricityOrder?.details.accountNumber
                                                    } 
                                                </b>
                                                
                                                    <span> {success ? "was successful" : "failed"}.</span>
                                                    {success ? " Thank you for using PreponeBills" : " Please try again."}
                                            </p>

                                            



                                            


                                           { success && <p> <span>Your Transaction Details:</span>
                                                    
                                                   <br />
                                                   <br />
                                                
                                                <span>Token: {token }</span>

                                               
                                            </p>}
                                            



                                            {/* <div className="invoice-option text-center">
                                                <Link to="#" className="invoice-btn"><i className="far fa-file-pdf"></i>Save as PDF</Link>
                                                <Link to="#" className="invoice-btn"><i className="fas fa-print"></i>Print Receipt</Link>
                                                <Link to="#" className="invoice-btn"><i className="far fa-envelope"></i>Email Receipt</Link>
                                                <p className="mt-4 mb-0"><Link to="/electricity-order" className="btn btn-default">Make another Recharge</Link></p>
                                            </div> */}
                                        </div>

                                }


                            </div>
                        </div>
                    </div>
                    { loading && <Spinner /> }

                </div>
  )
}

export default ElectricityDoneComp