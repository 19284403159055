import React, { useEffect, useState} from 'react'
import { Link, useNavigate } from "react-router-dom"
import { addCableTVPhoto } from '../../redux/slice/cableTVSlice/cableTVPhotoSlice'
import { addCableTVOrder } from '../../redux/slice/cableTVSlice/cableTVSlice'
import { addDSTVOrder } from '../../redux/slice/cableTVSlice/dstvSlice'
import { addGOTVOrder } from '../../redux/slice/cableTVSlice/gotvSlice'
import { addStartimesOrder } from '../../redux/slice/cableTVSlice/startimesSlice'
import { postCableTVTransaction, postDSTVTransaction, postGOTVTransaction, postStartimesTransaction } from '../../utils/processTransactions/processCableTV/processCableTVTransactions'
// import { toast } from 'react-toastify'
import { useAppDispatch, useAppSelector } from "../../utils/TypeScript"
import Spinner from '../Alert/Spinner/Spinner'
import { toast } from 'react-toastify'


const CableTVDoneComp = () => {

    const [success, setSuccess] =useState(false)
    const [loading, setLoading] =useState(false)
    const [post, setPost] =useState(false)
    

    const dispatch = useAppDispatch()
    const navigate = useNavigate()


    const { currentCableTVPhoto } = useAppSelector(state => state.cableTVPhoto)
    const { currentCableTVOrder } = useAppSelector(state => state.cableTV)


    const redirectToHome = () =>{
        setTimeout(()=>{
            
            localStorage.clear();
        }, 50000)
    }


    const postTransaction = async () => {
        if (!currentCableTVOrder) return; 
        
        try {
            setLoading(true);
            const transactionResponse = await postCableTVTransaction(currentCableTVOrder);
    
            if (transactionResponse?.responseMessage?.toLowerCase() === "successful") {
                setSuccess(true) 
                toast.success("Transaction completed successfully!");
                localStorage.clear();
                // redirectToHome();
            } else {
                throw new Error("Transaction failed. Please try again.");
            }
        } catch (error: any) {
            toast.error(error.message || "An unexpected error occurred.");
        } finally {
            setLoading(false);
        }
    };

    // const getFromLocalStorage = ()=>{
    //     const cableTVPhoto =  JSON.parse(localStorage.getItem('cableTVPhoto') || "{}");
    //     const cableTVOrder =  JSON.parse(localStorage.getItem('cableTVOrder') || "{}");

    //     dispatch(addCableTVPhoto(cableTVPhoto))
    //     dispatch(addCableTVOrder(cableTVOrder))


    //     setPost(true)
    // }

    // useEffect(()=>{
    //     if(localStorage.getItem('cableTVPhoto') === null ) return navigate("/")

    //     getFromLocalStorage()

    // }, [])

    useEffect(()=>{
        // if(localStorage.getItem('cableTVPhoto') === null ) return navigate("/")
        if(localStorage.getItem('payment') === null ) return navigate("/")

        const payment =  localStorage.getItem('payment') || " ";
        if(payment !== "i") return setLoading(false)
        
            if (currentCableTVOrder && Object.keys(currentCableTVOrder).length > 0) {
                postTransaction();
            }
    }, [currentCableTVOrder])


  return (
    <div className="col-lg-9">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="bg-light shadow-sm text-center mb-3">
                                <div className="d-flex admin-heading pr-3">
                                    <div className="mr-auto">
                                    <h4 className="text-9 font-weight-400" style={{color: "#3f0d5e"}}><i className="fas fa-wallet mr-2"></i>
                                        {
                                            currentCableTVPhoto?.billerCode === 'DSTV1' ? "DSTV"
                                            : 
                                            currentCableTVPhoto?.billerCode === 'GOTV1' ? "GOTV"
                                            : 
                                            currentCableTVPhoto?.billerCode === 'startimes' ? "Startimes"
                                            : 
                                            ""
                                        }
                                    </h4>
                                    </div>
                                    <div className="ml-auto">
                                    <h4 className="text-9 font-weight-400" style={{color: "#3f0d5e"}}>₦
                                        {
                                            currentCableTVOrder?.details.amount
                                        }
                                    </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-md-12 profile-content ">
                        <ul className="nav nav-pills">
                
                            <li className="nav-item">
                                <Link className="nav-link" to="">Order</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="">Summary</Link>
                            </li>
                            {/* <li className="nav-item">
                                <Link className="nav-link" to="/recharge-payment">Payment</Link>
                            </li> */}
                            <li className="nav-item">
                                <Link className="nav-link active" to="" >Done</Link>
                            </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="five" role="tabpanel" aria-labelledby="five-tab">
                                

                                {
                                    loading ? 
                                        
                                        ""

                                    :
                                
                                
                                        <div className="done my-5">
                                            <h3 className="title">
                                                {
                                                    success ? 
                                                        "Transaction Successful"
                                                    :
                                                        "Transaction Failed"
                                                }
                                            </h3>

                                            <span className={`text-5 d-block  mt-4 mb-3 ${success? "text-success" : "text-danger"}`}>
                                                {
                                                    success ? 
                                                        <i className="fas fa-check-circle"></i>
                                                    :
                                                        <i className="fas fa-times-circle"></i>
                                                }
                                            </span>

                                            {/* <p>Your payment ₦{currentOrder?.amount} {success ? "    was successfully" : "failed"} <br/>
                                                to receive <b>+1 408 7094360</b> Mobile Operator.
                                            </p> */}



                                            <p> <span>Your </span>
                                                

                                                    { 
                                                        // currentCableTVPhoto?.billerCode === 'DSTV1' ? dstvProduct 
                                                        // : 
                                                        // currentCableTVPhoto?.billerCode === 'GOTV1' ? gotvProduct
                                                        // : 
                                                        // currentCableTVPhoto?.billerCode === 'startimes' ? currentStartimesOrder?.transDetails[4].fieldValue
                                                        // : 
                                                        // ""
                                                        currentCableTVOrder?.details.packageName
                                                    } 
                                                    
                                                    <span> subscription of ₦</span>
                                                    
                                                    {
                                                        currentCableTVOrder?.details.amount
                                                    } 
                                                    
                                                <br/>
                                                to <b>
                                                    
                                                    {
                                                        currentCableTVOrder?.details.accountNumber
                                                    } 
                                                </b>
                                                
                                                    <span> {success ? "was successful" : "failed"}.</span>
                                                    {success ? " Thank you for using PreponeBills" : " Please try again."}
                                            </p>




                                            {/* <div className="invoice-option text-center">
                                                <Link to="#" className="invoice-btn"><i className="far fa-file-pdf"></i>Save as PDF</Link>
                                                <Link to="#" className="invoice-btn"><i className="fas fa-print"></i>Print Receipt</Link>
                                                <Link to="#" className="invoice-btn"><i className="far fa-envelope"></i>Email Receipt</Link>
                                                <p className="mt-4 mb-0"><Link to="/cabletv-order" className="btn btn-default">Make another Recharge</Link></p>
                                            </div> */}
                                        </div>


                                }

                            </div>
                        </div>
                    </div>
                    { loading && <Spinner /> }

                </div>
  )
}

export default CableTVDoneComp