import React, { useState, useEffect} from 'react'
import { Link, useNavigate } from "react-router-dom"
import { addAirtimePhoto } from '../../redux/slice/airtimeSlice/airtimePhotoSlice'
import { addAirtimeOrder } from '../../redux/slice/airtimeSlice/airtimeSlice'
import { addEducationPhoto } from '../../redux/slice/educationSlice/educationPhoto'
import { addJambOrder } from '../../redux/slice/educationSlice/jambSlice'
import { addWAECPINOrder } from '../../redux/slice/educationSlice/waecPINSlice'
import { postJAMBTransaction, postWAECPINTransaction, postWAECRegistrationTransaction } from '../../utils/processTransactions/processEducation/educationTransactions'
import { useAppSelector, useAppDispatch } from "../../utils/TypeScript"
import Spinner from '../Alert/Spinner/Spinner'


const EducationDoneComp = () => {

    const [loading, setLoading] =useState(false)
    const [success, setSuccess] =useState(false)
    const [pin, setPin] =useState("")
    const [serial, setSerial] =useState("")

    const dispatch = useAppDispatch()
    const navigate = useNavigate()


    const { currentEducationPhoto } = useAppSelector(state => state.educationPhoto)
    const { currentJambOrder } = useAppSelector(state => state.jamb)
    const { currentWAECPINOrder } = useAppSelector(state => state.waecPIN)
    

    const redirectToHome = () =>{
        setTimeout(()=>{
            // navigate("/")
        }, 50000)
    }

    const postJAMBBTransaction = async () =>{
        if (!currentJambOrder) return; 

        setLoading(true)

        if(currentJambOrder != null) {
            const transactionResponse = await postJAMBTransaction(currentJambOrder)
            // const {  responseMessage } = transactionResponse

            console.log(transactionResponse);

            if(transactionResponse?.responseMessage?.toLowerCase() === "successful" ){
                setSuccess(true) 
                setLoading(false)
                localStorage.clear();
                redirectToHome()

            }else{
                localStorage.clear();
                redirectToHome()
                return setLoading(false)
            }

 
        }
    }


    const postWAECTransaction = async () =>{
        if (!currentWAECPINOrder) return; 

        if(currentWAECPINOrder != null) {
            if(currentWAECPINOrder?.uniqueCode === "WAEC_94533"){
                const transactionResponse = await postWAECPINTransaction(currentWAECPINOrder)
                const {  responseMessage, data } = transactionResponse
    
                console.log(currentWAECPINOrder);
                console.log(transactionResponse);

    
                if(responseMessage.toLowerCase() === "successful" ){
                    setSuccess(true) 
                    setPin(data?.pin)
                    setSerial(data?.serial)
                    setLoading(false)
                    localStorage.clear();
                    redirectToHome()
    
                }else{
                    localStorage.clear();
                    redirectToHome()
                    return setLoading(false)
                }
                // if(currentWAECPINOrder?.uniqueCode === "WAEC_03174")
            } else  { 
                console.log({currentWAECPINOrder: currentWAECPINOrder});
                
                const transactionResponse = await postWAECRegistrationTransaction(currentWAECPINOrder)
                const {  responseMessage } = transactionResponse
    
                console.log(transactionResponse);

    
                if(responseMessage.toLowerCase() === "successful" ){
                    setSuccess(true) 
                    setLoading(false)
                    localStorage.clear();
                    redirectToHome()
    
                }else{
                    localStorage.clear();
                    redirectToHome()
                    return setLoading(false)
                }
            }

        }
    }

    const postTransaction = async () =>{
        if(currentEducationPhoto?.billerCode === 'JAMB'){
            postJAMBBTransaction()

        } else  if(currentEducationPhoto?.billerCode === 'WAEC') {
            postWAECTransaction()
        } 
    }
  
   

    // const getFromLocalStorage = async ()=>{
    //     setLoading(true)

    //     const educationPhoto =  JSON.parse(localStorage.getItem('educationPhoto') || "{}");
    //     const jambOrder =  JSON.parse(localStorage.getItem('jambOrder') || "{}");
    //     const waecPINOrder = JSON.parse(localStorage.getItem('waecPINOrder') || "{}");
       

    //     dispatch(addEducationPhoto(educationPhoto))
    //     dispatch(addJambOrder(jambOrder))
    //     dispatch(addWAECPINOrder(waecPINOrder))
       

    // }


    // useEffect(()=>{
    //     if(localStorage.getItem('educationPhoto') === null ) return navigate("/")

    //     getFromLocalStorage()
    // }, [ ])
    
    useEffect(()=>{
        // if(localStorage.getItem('educationPhoto') === null ) return navigate("/")
        if(localStorage.getItem('payment') === null ) return navigate("/")

        const payment =  localStorage.getItem('payment') || " ";
        if(payment !== "i") return setLoading(false)
           
            if (currentJambOrder && Object.keys(currentJambOrder).length > 0) {
                postTransaction();
            }
    }, [currentJambOrder])




  return (
    <div className="col-lg-9">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="bg-light shadow-sm text-center mb-3">
                                <div className="d-flex admin-heading pr-3">
                                    <div className="mr-auto">
                                    <h4 className="text-9 font-weight-400" style={{color: "#3f0d5e"}}><i className="fas fa-wallet mr-2"></i>
                                    {
                                        currentEducationPhoto?.billerCode === 'WAEC' ? "WAEC"
                                        : 
                                        currentEducationPhoto?.billerCode === 'JAMB' ? "JAMB"
                                        : 
                                        ""
                                    }
                                
                                    </h4>
                                    </div>
                                    <div className="ml-auto">
                                    <h4 className="text-9 font-weight-400" style={{color: "#3f0d5e"}}>₦
                                    {
                                        currentEducationPhoto?.billerCode === 'WAEC' ? currentWAECPINOrder?.details?.amount
                                        : 
                                        currentEducationPhoto?.billerCode === 'JAMB' ? currentJambOrder?.details?.amount
                                        : 
                                        ""
                                        
                                    }
                                    </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-md-12 profile-content ">
                        <ul className="nav nav-pills">
                
                            <li className="nav-item">
                                <Link className="nav-link" to="">Order</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="">Summary</Link>
                            </li>
                            {/* <li className="nav-item">
                                <Link className="nav-link" to="/recharge-payment">Payment</Link>
                            </li> */}
                            <li className="nav-item">
                                <Link className="nav-link active" to="" >Done</Link>
                            </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="five" role="tabpanel" aria-labelledby="five-tab">
                                
                                {
                                    loading ? 

                                        ""
                                    :
                                
                                
                                        <div className="done my-5">
                                            <h3 className="title">
                                                {
                                                    success ? 
                                                        "Transaction Successful"
                                                    :
                                                        "Transaction Failed"
                                                }
                                            </h3>

                                            <span className={`text-5 d-block  mt-4 mb-3 ${success? "text-success" : "text-danger"}`}>
                                                {
                                                    success ? 
                                                        <i className="fas fa-check-circle"></i>
                                                    :
                                                        <i className="fas fa-times-circle"></i>
                                                }
                                            </span>

                                            {/* <p>Your payment ₦{currentOrder?.amount} {success ? "    was successfully" : "failed"} <br/>
                                                to receive <b>+1 408 7094360</b> Mobile Operator.
                                            </p> */}

                                            {/* JAMB MESSAGE */}

                                            {
                                                currentEducationPhoto?.billerCode === 'JAMB' ?

                                                <p> <span>Your </span>
                                                
                                                        JAMB

                                                    <span> payment of ₦</span>
                                                        {
                                                            currentJambOrder?.details?.amount
                                                        } 
                                                    <br/>
                                                    for <b>
                                                        {
                                                            currentJambOrder?.details?.profileCode
                                                        } 
                                                    </b>
                                                    <span> {success ? "was successful" : "failed"}.</span>
                                                    
                                                    {success ? " Thank you for using PreponeBills" : " Please try again."}
                                                </p>

                                                : 
                                                ""

                                            }

                                            {/* JAMB PIN DETAILS */}
                                            {
                                                currentEducationPhoto?.billerCode === 'JAMB' ?
                                                <div>

                                                    { success && <p> <span>Your Transaction Details:</span>
                                                            
                                                           <br />
                                                        
                                                        {/* <span>{resMsg}</span> */}
        
                                                       
                                                    </p>}
                                                </div>


                                                :

                                                ""
                                            }


                                            

                                            {/* WAEC MESSAGE */}


                                            {
                                                currentEducationPhoto?.billerCode === 'WAEC' ?

                                                <p> <span>Your </span>
                                                
                                                        {
                                                            currentWAECPINOrder?.uniqueCode === "WAEC_94533" ? "WAEC PIN" 
                                                            : 
                                                            currentWAECPINOrder?.uniqueCode === "WAEC_03174" ?
                                                            "WAEC REGISTRATION"
                                                            :
                                                            ""
                                                        }

                                                    <span> payment of ₦</span>
                                                        {
                                                            currentWAECPINOrder?.details?.amount
                                                        } 
                                                    <br/>
                                                    for <b>
                                                        {
                                                            currentWAECPINOrder?.details?.phoneNumber
                                                        } 
                                                    </b>
                                                    <span> {success ? "was successful" : "failed"}.</span>
                                                    
                                                    {success ? " Thank you for using PreponeBills" : " Please try again."}
                                                </p>

                                                : 
                                                ""

                                            }


                                            {/* WAEC DETAILS */}
                                            {
                                                currentWAECPINOrder?.uniqueCode === "WAEC_94533" ?
                                                <div>

                                                    { success && <p> <span>Your Transaction Details:</span>
                                                            
                                                           <br />
                                                        
                                                        <span>Pin: {pin}</span>
                                                        <span>Serial: {serial}</span>
        
                                                       
                                                    </p>}
                                                </div>

                                                :

                                                ""
                                            }

                                            

                                            {/* <div className="invoice-option text-center">
                                                <Link to="#" className="invoice-btn"><i className="far fa-file-pdf"></i>Save as PDF</Link>
                                                <Link to="#" className="invoice-btn"><i className="fas fa-print"></i>Print Receipt</Link>
                                                <Link to="#" className="invoice-btn"><i className="far fa-envelope"></i>Email Receipt</Link>
                                                <p className="mt-4 mb-0"><Link to="/recharge-order" className="btn btn-default">Make another Recharge</Link></p>
                                            </div> */}
                                        </div>

                                }


                            </div>
                        </div>
                    </div>
                    { loading && <Spinner /> }
                </div>
  )
}

export default EducationDoneComp